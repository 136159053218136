import dynamic from "next/dynamic";
import Container from "_components/_layout/Container";
// import GravityForm from "_components/_layout/GravityForm";
import Heading from "_components/_layout/Heading";
import styles from "./styles.module.scss";

const DynamicGravityForm = dynamic(() => import("../_layout/GravityForm"));

const Contact = ({ formData }) => {
  return (
    <div id="contact" className={styles.contactContainer}>
      <Container>
        <div className={styles.content}>
          <div className={styles.heading}>
            <Heading appearance="blueBar">Contact</Heading>
          </div>
          <div className={styles.formContainer}>
            <DynamicGravityForm
              id={formData.formId}
              fields={formData.formFields.nodes}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
