import Image from "next/image";
import styles from "./styles.module.scss";

import Container from "_components/_layout/Container";
import Heading from "_components/_layout/Heading";

const About = () => {
  return (
    <>
      <div id="about" className={styles.aboutContainer}>
        <Container>
          <div className={styles.content}>
            <Heading appearance="blueBar">About</Heading>
            <div className={styles.paragraphContainer}>
              <p className={styles.subText}>
                Serving Houston in the renovation and construction industry for
                more than 10 years, owner Giovanny Sanchez is committed to
                keeping families safe and protected. With state-of-the-art
                techniques to inspect your roof, we work quickly to get the job
                done while maintaining high standards.
              </p>
              <div className={styles.aboutImage}>
                <Image
                  src="/static/images/about-img.png"
                  width="320px"
                  height="320px"
                  objectFit="contain"
                  alt="About image of Sanson LLC owners."
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default About;
