import Heading from "_components/_layout/Heading";
import Button from "_components/_layout/LinkButton";
import styles from "./styles.module.scss";

const Hero = () => {
  return (
    <>
      <div className={styles.headerSpacer}></div>
      <div className={styles.heroContainer}>
        <div className={styles.hero}>
          <Heading appearance="noBar">Roofing Service You Can Trust</Heading>
          <p>
            Sanson Construction and Renovation is a family owned roofing company
            serving residential customers in the Greater Houston area.
          </p>
          <div className={styles.buttonGroup}>
            <Button link="/#services">Services</Button>
            <Button link="/#contact">Free Estimate</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
