import Link from "next/link";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";

export interface HeadingTypes {
  appearance: string;
  children: ReactNode;
}

const Heading = ({ appearance, children }: HeadingTypes) => {
  return (
    <div className={styles.headingContainer}>
      <div className={classNames(styles[appearance])}>
        {appearance === "noBar" ? (
          <h1 className={styles.heading}>{children}</h1>
        ) : (
          <h2 className={styles.heading}>{children}</h2>
        )}
      </div>
    </div>
  );
};

export default Heading;
