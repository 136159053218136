import Container from "_components/_layout/Container";
import Heading from "_components/_layout/Heading";
import Image from "next/image";
import Link from "next/link";
import Panel from "_components/_layout/Panel";
import styles from "./styles.module.scss";

const services = [
  {
    title: "Installations",
    serviceText:
      "With Sanson, you can expect quality and precision with every residential roof installation. Our team installs quality shingle roofs that ensure safety, longevity and add value to your home. Call us today!",
    serviceImage: "/static/images/installation.png",
  },
  {
    title: "Repairs",
    serviceText:
      "Storm damage and cracks can lead to much bigger problems. We undersand the urgency with roof repairs, so we provide our customers with quick turn around times. Our roofing contractors will evaluate the damage and act fast to schedule your repairs.",
    serviceImage: "/static/images/repairs.png",
  },
  {
    title: "Inspections & Estimates",
    serviceText:
      "Sanson Construction and Renovation provides free estimates to evaluate the best option for your shingle roof. Schedule your consultation today!",
    serviceImage: "/static/images/inspections-estimates.png",
  },
];

const Service = () => {
  return (
    <div id="services" className={styles.servicesContainer}>
      <Container>
        <div className={styles.content}>
          <Heading appearance="whiteBar">Services</Heading>
          <div className={styles.paragraphContainer}>
            <p className={styles.subText}>
              Our fully insured Certified Master Roofing Contractors offer the
              best roofing services in Houston, ranging from residential roof
              repairs to full roof replacements. Our services include roof
              inspections to locate structural damage, residential roof repair
              including metal roofs, traditional roof repair, and roof tear-off
              and installation.
            </p>
            <div className={styles.serviceImage}>
              {/* <Link href={content.serviceImageLink}>
              <a target="_blank" rel="noopener">
                <Image
                  src="/static/images/BBB-logo1W@2x.png"
                  width="240px"
                  height="240px"
                  layout="fixed"
                  objectFit="contain"
                  alt="BBB SanSon Roofing"
                />
                <p className={styles.bbbProfile}>click for profile</p>
              </a>
              </Link> */}
            </div>
          </div>
          <div className={styles.panelsContainer}>
            {services
              ? services.map((service, key) => {
                  const heading = service.title;
                  const text = service.serviceText;
                  const image = {
                    sourceUrl: service.serviceImage,
                    alt: service.serviceText,
                  };
                  console.log("image:", image);
                  return (
                    <Panel
                      image={image}
                      heading={heading}
                      text={text}
                      key={key}
                    />
                  );
                })
              : null}
          </div>
          <div className={styles.bottomServiceImage}>
            {/* <Link href="https://www.bbb.org/us/tx/houston/profile/construction/sanson-construction-renovation-llc-0915-90061339#sealclick">
              <a target="_blank" rel="noopener">
                <Image
                  src="/static/images/BBB-logo1W@2x.png"
                  width="240px"
                  height="240px"
                  layout="fixed"
                  objectFit="contain"
                  alt="Sanson llc at the Better Business Bureau"
                />
                <p className={styles.bbbProfile}>click for profile</p>
              </a>
            </Link> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Service;
