import Link from "next/link";
import styles from "./styles.module.scss";
export interface LinkButtonTypes {
  link: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const LinkButton = ({ onClick, link, children }: LinkButtonTypes) => {
  return (
    <>
      {link ? (
        <div className={styles.linkButton}>
          <Link href={link}>
            <a className={styles.link}>{children}</a>
          </Link>
        </div>
      ) : (
        <div className={styles.link} onClick={onClick}>
          <span className={styles.linkButton}>{children}</span>
        </div>
      )}
    </>
  );
};

export default LinkButton;
