import { getContent } from "lib/api";
import Contact from "_components/Contact";
import Hero from "_components/Hero";
import About from "_components/About";
import Service from "_components/Service";

export default function Home({ data }) {
  const formData = data?.gfForm;

  return (
    <div className="relative">
      <Hero />
      <About />
      <Service />
      <Contact formData={formData} />
    </div>
  );
}

export async function getStaticProps() {
  const data = await getContent();
  return {
    props: {
      data,
    },
  };
}
