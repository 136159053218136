import styles from "./styles.module.scss";

export interface PanelTypes {
  heading: string;
  text: string;
  image: {
    alt: string;
    sourceUrl: string;
  };
}

const Panel = ({ image, heading, text }: PanelTypes) => {
  const backgroundImage = {
    backgroundImage: `url(${image.sourceUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "200px",
    width: "100%",
  };

  return (
    <>
      <div className={styles.panelWrapper}>
        <div className={styles.panelImage} style={backgroundImage}>
          {image ? (
            <div className={styles.desktopHeading}>
              <h3>{heading}</h3>
            </div>
          ) : null}
        </div>
        <div className={styles.panelContainer}>
          <div>
            <h3 className={styles.mobileHeading}>{heading}</h3>
          </div>
          <div>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Panel;
